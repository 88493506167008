(function () {
    function r(e, n, t) {
        function o(i, f) {
            if (!n[i]) {
                if (!e[i]) {
                    var c = "function" == typeof require && require;if (!f && c) return c(i, !0);if (u) return u(i, !0);var a = new Error("Cannot find module '" + i + "'");throw a.code = "MODULE_NOT_FOUND", a;
                }var p = n[i] = { exports: {} };e[i][0].call(p.exports, function (r) {
                    var n = e[i][1][r];return o(n || r);
                }, p, p.exports, r, e, n, t);
            }return n[i].exports;
        }for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
            o(t[i]);
        }return o;
    }return r;
})()({ 1: [function (require, module, exports) {
        window.onload = function () {
            var $wrapper = $('.clouds-parallax-wrap');
            var $layers = $wrapper.find('.cloud');

            if ($layers.length > 0) {
                $wrapper.on('mousemove', function (e) {
                    var pageX = e.clientX - window.innerWidth / 5;
                    var pageY = e.clientY - window.innerHeight / 5;

                    $layers.each(function (index, layer) {
                        if (index % 2 === 0) {
                            layer.style.transform = 'translateX(+' + (1 + pageX / 200) + '%) translateY(-' + (1 + pageY / 200) + '%)';
                        } else {
                            layer.style.transform = 'translateX(-' + (1 + pageX / 200) + '%) translateY(+' + (1 + pageY / 200) + '%)';
                        }
                    });
                });
            }
        };
    }, {}] }, {}, [1]);